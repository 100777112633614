var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"addressinfo"},[_c('el-radio-group',{staticStyle:{"margin-bottom":"30px"},attrs:{"size":"small"},on:{"input":_vm.getData},model:{value:(_vm.query.complaintStatus),callback:function ($$v) {_vm.$set(_vm.query, "complaintStatus", $$v)},expression:"query.complaintStatus"}},[_c('el-radio-button',{attrs:{"label":"0"}},[_vm._v("待处理")]),_c('el-radio-button',{attrs:{"label":"1"}},[_vm._v("处理中")]),_c('el-radio-button',{attrs:{"label":"2"}},[_vm._v("完成")]),_c('el-radio-button',{attrs:{"label":"3"}},[_vm._v("撤诉")]),_c('el-radio-button',{attrs:{"label":"4"}},[_vm._v("上诉")]),_c('el-radio-button',{attrs:{"label":"-1"}},[_vm._v("终止")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"row-class-name":_vm.tableClass,"data":_vm.tableData,"cell-style":{ textAlign: 'center' },"header-cell-style":{
        textAlign: 'center',
        backgroundColor: '#f7acb2',
        color: '#fff',
        fontSize: '15px',
        fontWeight: 400,
      }}},[_c('el-table-column',{attrs:{"prop":"content","label":"内容"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"sbtxt",on:{"click":function($event){return _vm.showtextDia(scope.row.content)}}},[_vm._v(" "+_vm._s(scope.row.content)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"日期"}}),_c('el-table-column',{attrs:{"prop":"handleUser","label":"被投诉人"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.complainedUserName ? scope.row.complainedUserName : scope.row.complainedMemberUserName ? scope.row.complainedMemberUserName : "")+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.complaintStatus == 0 ? "待处理" : scope.row.complaintStatus == 1 ? "处理中" : scope.row.complaintStatus == 2 ? "完成" : scope.row.complaintStatus == 3 ? "撤诉" : scope.row.complaintStatus == 4 ? "上诉" : "终止")+" ")]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.complaintStatus == 4)?_c('el-button',{staticStyle:{"color":"#00a1ff"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.ceshu(scope.row)}}},[_vm._v("撤诉")]):_vm._e(),(
              scope.row.complaintStatus == 0 ||
              scope.row.complaintStatus == 1 ||
              scope.row.complaintStatus == 2
            )?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.shangsu(scope.row)}}},[_vm._v("上诉")]):_vm._e()]}}])})],1),_c('div',{staticClass:"fenye"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.query.pageSize,"total":_vm.total},on:{"current-change":_vm.currentChange}})],1)],1),_c('el-dialog',{attrs:{"title":"投诉内容","visible":_vm.textDia,"closeOnClickModal":false,"width":"60%"},on:{"update:visible":function($event){_vm.textDia=$event}}},[_c('div',[_vm._v("投诉内容："+_vm._s(_vm.text))]),_c('el-button',{staticStyle:{"margin-top":"30px"},attrs:{"size":"small"},on:{"click":function($event){_vm.textDia = false}}},[_vm._v("关闭")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }