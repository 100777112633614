 
<template>
  <div>
    <gmap-map :center="centers" :zoom="11" ref="gmaps" :options="options" style="width: 100%;height: 380px;"
      :class="heightauto ? 'heightauto' : ''" @click="updateMaker">
      <gmap-marker :position="position" :icon="icon" :draggable="true" @dragend="updateMaker" @click="getNowPlaceClick" />
    </gmap-map>
    <div class="searchBtn">
      <!-- <GmapAutocomplete ref="GmapAutocomplete" @place_changed="setPlace" placeholder="请输入查询地址"/> -->
    </div>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
export default {
  components: {},
  props: {
    heightauto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        gestureHandling: "greedy",
      },
      check: true,
      icon: "https://1352.shuntun.com/prod-api/profile/upload/dibiao.png",
      newAddress: "",
      place: null,
      greedy: "greedy",
      position: {
        lat: 43.648509660046656,
        lng: -79.3789402652274,
      },
    };
  },
  computed: {
    google: gmapApi,
    centers() {
      return {
        lat: this.position.lat,
        lng: this.position.lng,
      };
    },
  },
  created() { },
  mounted() {
    console.log(this.$refs.gmaps);
    console.log(this.$refs.gmaps);
    console.log(this.$refs.gmaps);
    console.log(this.$refs.gmaps);
    this.geolocate();
  },
  methods: {
    clear() {
      document.getElementsByClassName(".pac-target-input").value = "";
      console.log(this.$refs.GmapAutocomplete);
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.pointToAddress(
          this.position.lat,
          this.position.lng,
          this.pushAddress
        );
      });
    },
    initPlace(e) {
      setTimeout(() => {
        this.position = {
          lat: e.latitude,
          lng: e.longitude,
        };
        this.pointToAddress(
          this.position.lat,
          this.position.lng,
          e.detailAddress
        );
      }, 500);
    },
    setPlace(place) {
      console.log(place);

      this.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      this.center = this.position;

      this.pointToAddress(
        this.position.lat,
        this.position.lng,
        this.pushAddress
      );
    },
    updateMaker: function (event) {
      console.log("updateMaker, ", event.latLng.lat(), event.latLng.lng());
      let data = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      this.$store.commit("setlatAndlng", data);


      this.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.pointToAddress(
        this.position.lat,
        this.position.lng,
        this.pushAddress
      );

      var latitude = event.latLng.lat()
      var longitude = event.latLng.lng()


      var that = this;
      var geocoder = new this.google.maps.Geocoder();
      // 地理反解析
      geocoder.geocode(
        {
          location: new this.google.maps.LatLng(latitude, longitude),
        },
        function geoResults(results, status) {
          let detailAddress = results[0].formatted_address;

          that.$store.commit("setPlace", detailAddress);
        }
      );
    },
    pushAddress(res) {
      this.$emit("mark", res, this.position);
    },
    pointToAddress(lat, lng, backcall) {
      // 实例化Geocoder服务用于解析地址
      var geocoder = new this.google.maps.Geocoder();
      // 地理反解析
      geocoder.geocode(
        { location: new this.google.maps.LatLng(lat, lng) },
        function geoResults(results, status) {
          let data = {
            title: results[0].formatted_address,
            latitude: lat,
            longitude: lng,
          };
          backcall(data);
        }
      );
    },
    getNowPlaceClick(e) {
      this.$emit("getNowPlaceClick");
    },
  },
};
</script>

<style>
.pac-container {
  z-index: 1000000000;
}

.heightauto {
  height: calc(100vh - 120px) !important;
}
</style>
<style lang="scss" scoped>
.searchBtn {
  background-color: #fff;
  padding: 10px 0;
}

.pac-target-input {
  outline: none;
  width: 330px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>
 