<template>
  <div class="vip">
    <div class="title">
      <span class="name">个人信息</span>
    </div>

    <div class="userInfo">
      <div class="label">
        <!-- <span>昵称：{{ userInfo.nickName }}</span> -->
        <!-- <span>入驻：{{ userInfo.days }}天</span> -->
      </div>
      <div class="user">
        <!-- <el-image fit="contain" class="touxiang" :src="goodsData.headUrl">
        </el-image> -->
        <div>
          <div class="desc">编码： {{ goodsData.memberCode }}</div>
          <div class="desc">账号： {{ goodsData.account }}</div>
          <div class="desc">密码：<span @click="toCHongzhi">重置</span></div>
          <div
            class="desc"
            @click="changeNickename"
            v-if="goodsData.canUpdateNickName"
          >
            昵称： 设置（昵称只能设置一次！）
          </div>
          <div class="desc" v-else>昵称： {{ goodsData.nickName }}</div>
          <!-- <div class="desc">
            姓名：{{ goodsData.realName ? goodsData.realName : "暂无" }}
          </div> -->
          <div class="desc">
            身份：{{
              goodsData.levelInputName ? goodsData.levelInputName : "暂无"
            }}
          </div>
          <!-- <div class="desc">
            证件照：<el-link @click="seeDia( goodsData.cardPic)">查看<i class="el-icon-view el-icon--right"></i> </el-link>
          </div> -->
          <div class="desc">
            微信：{{ goodsData.wx ? goodsData.wx : "暂无" }}
          </div>
          <div class="desc">
            微信二维码：<el-link @click="seeDia( goodsData.wxPic)">查看<i class="el-icon-view el-icon--right"></i> </el-link>
          </div>
          
          <div class="desc">邮箱：{{ goodsData.email }}</div>
          <div class="desc">手机：{{ goodsData.phone }}</div>

          <!-- <div class="desc">
            姓名： {{goodsData.realName}} 
          </div>
          <div class="desc">
            邮箱： {{goodsData.email}}
          </div> -->
        </div>
      </div>
      <div class="btns">
        <div class="btn" @click="changeMy">
          <span>编辑信息</span>
        </div>
      </div>

    </div>

    <el-dialog
      title="编辑信息"
      :visible.sync="editUserDia"
      :closeOnClickModal="false"
      width="60%"
    >
    <edituser ref="edituser" @closeDia="closeDia"></edituser>

    </el-dialog>
    <el-dialog
      title="查看图片"
      :visible.sync="imageDia"
      :closeOnClickModal="false"
      width="60%"
    >
    <img :src="imgurl" class="diaimgs" style="width:100%" alt="">

    </el-dialog>
  </div>
</template>

<script>
import { memberUserinfo } from "@/request/api.js";
import { getmemberUserinfo, memberUserupdateInfo } from "@/request/api.js";
import edituser from './edituser.vue'
export default {
  name: "userdata",
  components:{
    edituser
  },
  data() {
    return {
      editUserDia:false,
      goodsData: "",
      action: process.env.VUE_APP_BASE_RUL + "common/upload",
      imgurl:"",
      imageDia:false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    closeDia(){
      this.getData()
      this.editUserDia = false
    },
    changeNickename() {
      this.$prompt("请输入昵称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        if (!value) {
          return this.$message("昵称不能为空");
        } else {
          this.goodsData.nickName = value;
          memberUserupdateInfo(this.goodsData).then((res) => {
            this.$message.success("修改成功");
          });
        }
      });
    },
    getData() {
      memberUserinfo().then((res) => {
        console.log(res);
        this.goodsData = res.data;
      });
    },
    toCHongzhi() {
      this.$router.push({
        path: `/login?Inv=3`,
      });
    },
    handleAvatarSuccess(res) {
      console.log(res);
      this.goodsData.headUrl = res.data.url;
      memberUserupdateInfo(this.goodsData).then((res) => {
        this.$message.success("修改成功");
      });
    },
    changeMy(){
      this.editUserDia = true
      this.$nextTick(()=>{
        this.$refs.edituser.init(this.goodsData)
      })
    },
    seeDia(url){
      if(url){
        console.log(url)
        this.imgurl = url
        this.imageDia = true
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.diaimgs{
  width: 250px!important;
  display: block;
  margin: 0 auto;
}
.kefuDia {
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      width: 50px;
      margin-right: 10px;
      border-radius: 10px;
    }
    div {
      margin-bottom: 4px;
    }
  }
  .num {
    margin-top: 5px;
  }
}
.vip {
  padding: 20px 20px 10px 20px;

  .userInfo {
    background: #fff;
    padding: 10px 20px 20px 20px;
    .label {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      color: #545454;
    }
    .user {
      display: flex;
      .touxiang {
        min-width: 200px;
        border-radius: 10px;
        margin-right: 20px;
      }
      .name {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: bold;
      }
      .desc {
        margin-bottom: 10px;
        font-size: 15px;
        cursor: pointer;
      }
    }

    .btns {
      margin-top: 20px;
      padding-left: 220px;
      display: flex;
      align-items: center;
      .btn {
        width: 100px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: #0893ce;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        margin-right: 20px;
        .iconfont {
          margin-right: 8px;
          font-size: 17px;
        }
      }
      .btn:last-child {
        background: #fc7474;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .name {
      font-size: 20px;
      font-weight: bold;
      color: #545454;
    }
  }
}
</style>
