<template>
  <div class="vip">
    <div class="title" >
      <span class="name">私人助理</span>
      <!-- <el-popover placement="bottom" width="200" trigger="hover">
        <div class="kefuDia">
          <div class="top">
            <img :src="userInfo.assistantUser?userInfo.assistantUser.avatar:''" alt="" />
            <div class="text">
              <div>编号：{{ userInfo.assistantUser.userName }}</div>
              <div>{{ userInfo.assistantUser.nickName }}</div>
            </div>
          </div>
          <div class="num">电话：{{ userInfo.assistantUser.phonenumber }}</div>
          <div class="num">微信：{{ userInfo.assistantUser.wx }}</div>
        </div>
        <div slot="reference">
          <i class="iconfont">&#xe60a;</i>
          助理：{{ userInfo.assistantUser.nickName }}
        </div>
      </el-popover> -->
    </div>

    <div class="userInfo">
      <div class="label">
        <!-- <span>昵称：{{ userInfo.nickName }}</span> -->
        <!-- <span>入驻：{{ userInfo.days }}天</span> -->
      </div>
      <div class="user">
        <img class="touxiang" :src="userInfo.assistantUser?userInfo.assistantUser.certPhoto:''" alt="" />
        <div  v-if="userInfo.assistantUser">
          <div class="desc">
            姓名：{{ userInfo.assistantUser?userInfo.assistantUser.nickName:'暂无' }}
          </div>
          <div class="desc">编号：{{ userInfo.assistantUser.memberCode }}</div>
          <div class="desc">邮箱：{{ userInfo.assistantUser.email }}</div>
          <div class="desc">手机：{{  userInfo.assistantUser.phonenumber }}</div>
          <div class="desc">微信：{{ userInfo.assistantUser.wx }}</div>
        </div>
      </div>
      <div class="btns">

        <div class="btn" @click="tousu">
          <i class="iconfont">&#xe61c;</i>
          <span>投诉</span>
        </div>
     
      </div>
    </div>
    <el-dialog title="投诉" :visible.sync="dialogVisible" width="40%">
      <div v-if="userInfo.assistantUser">
      <div style="margin-bottom: 15px; font-size: 15px">
        被投诉人：{{ userInfo.assistantUser.nickName }}
      </div>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入投诉原因"
        style="margin-bottom:20px"
        v-model="sousuForm.content"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="tousuSubmit()" size="small"
          >确 定</el-button
        >
      </span>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import { memberUserinfo, complaintadd } from "@/request/api.js";
export default {
  name: "vip",
  data() {
    return {
      dialogVisible: false,
      textarea: "",
      userInfo: "",
      sousuForm: {
        complainedUser: "",
        content: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      memberUserinfo().then((res) => {
        console.log(res);
        this.userInfo = res.data;
      });
      console.log("123");
    },
    tousu() {
      this.sousuForm.complainedUser = this.userInfo.assistantUser.userId;
      this.dialogVisible = true;
    },
    loginOut() {
      this.$confirm("是否确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.push({
          path: "/login",
        });
      });
    },
    tousuSubmit() {
      if (this.sousuForm.content == "") {
        return this.$message("请输入投诉原因");
      }

      console.log(this.sousuForm);

      complaintadd(this.sousuForm).then((res) => {
        this.sousuForm.content = "";
        this.$notify({
          title: "成功",
          message: "投诉成功",
          type: "success",
        });
        this.dialogVisible = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.kefuDia {
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      width: 50px;
      margin-right: 10px;
      border-radius: 10px;
    }
    div {
      margin-bottom: 4px;
    }
  }
  .num {
    margin-top: 5px;
  }
}
.vip {
  padding: 20px 20px 10px 20px;

  .userInfo {
    background: #fff;
    padding: 10px 20px 20px 20px;
    .label {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      color: #545454;
    }
    .user {
      display: flex;
      .touxiang {
        width: 150px;
        border-radius: 10px;
        margin-right: 20px;
      }
      .name {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: bold;
      }
      .desc {
        margin-bottom: 5px;
        font-size: 15px;
      }
    }

    .btns {
      margin-top: 20px;
      padding-left: 170px;
      display: flex;
      align-items: center;
      .btn {
        width: 100px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: #0893ce;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        margin-right: 20px;
        .iconfont {
          margin-right: 8px;
          font-size: 17px;
        }
      }
      .btn:last-child {
        background: #fc7474;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .name {
      font-size: 20px;
      font-weight: bold;
      color: #545454;
    }
   
  }
}
</style>
