 
<template>
  <div>
    <div id="container" style="height: 300px;width: 100%;"></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
  },
  data() {
    return {
      latitude: '',
      longitude: '',
      map: null
    };
  },
  created() { },
  mounted() {

  },
  methods: {
    initPlace(e) {
      console.log(e)
      this.latitude = e.latitude
      this.latitude = e.longitude

      this.map = new qq.maps.Map(document.getElementById("container"), {
        center: new qq.maps.LatLng(this.latitude, this.longitude),
        zoom: 13
      });
      var anchor = new qq.maps.Point(6, 10),
        size = new qq.maps.Size(150, 100),
        origin = new qq.maps.Point(0, 0),
        icon = new qq.maps.MarkerImage('https://1352.shuntun.com/prod-api/profile/upload/dibiao.png', size, origin, anchor);


      var center = new qq.maps.LatLng(this.latitude, this.longitude);
      this.marker = new qq.maps.Marker({
        position: center,
        map: this.map,
        icon: icon,
        id: 1,
      });

      qq.maps.event.addListener(this.map, 'click', (event) => {
        console.log(event.latLng)

        this.longitude = event.latLng.lng;
        this.latitude = event.latLng.lat;

        let data = {
          longitude: this.longitude,
          latitude: this.latitude,
        }
        this.$emit('updateMaker', data)
        if (!this.marker) {
          this.marker = new qq.maps.Marker({
            position: event.latLng,
            map: this.map,
            icon: icon,
            id: 1,
          });
          return;
        }

        this.marker.setPosition(event.latLng);



      });

    }
  },
};
</script>

<style>
.pac-container {
  z-index: 1000000000;
}

.heightauto {
  height: calc(100vh - 120px) !important;
}
</style>
<style lang="scss" scoped>
.searchBtn {
  background-color: #fff;
  padding: 10px 0;
}

.pac-target-input {
  outline: none;
  width: 330px;
  position: relative;
  font-size: 14px;
  display: inline-block;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>
 