<template>
  <div>
    <div class="xinxi">
      <div class="title">
        <span class="name">工作台</span>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f7acb2',
          color: '#fff',
          fontSize: '15px',
          fontWeight: 400,
        }"
      >
        <el-table-column prop="name" label="业务名称"> </el-table-column>

        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goInfo(scope.row)"
              >进入</el-button
            >
            <el-button type="text" size="small" @click="out(scope.row)"
              >退出</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="fenye">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          :page-size="query.pageSize"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { businesslist, businessdelete } from "@/request/api.js";
export default {
  name: "xinxi",
  components: {},
  data() {
    return {
      tabPosition: "1",
      query: {
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    out(e) {
      console.log(e);
      this.$confirm("是否确认退出?", "提示", {
        type: "warning",
      }).then(() => {
        businessdelete({ id: e.id }).then((res) => {
          this.getData();

          this.$message({
            type: "success",
            message: "退出成功!",
          });
        });
      });
    },
    getData() {
      businesslist(this.query).then((res) => {
        console.log(res);
        this.total = res.total;
        this.tableData = res.rows;
        if(this.tableData.length>0){
          this.$emit('show',true)
        }else{
          this.$emit('show',false)
        }
      });
    },
    tableClass({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        //奇数行，序号不能被2整除
        return "even-row";
      } else {
        return "";
      }
    },
    goInfo(e) {
      console.log(e);
      let url = e.linkUrl;
      window.open(url);
    },
    back() {
      this.toInfo = false;
    },
    currentChange(e) {
      this.query.pageNum = e;
      this.getData();
    },
  },
};
</script>
<style>
.even-row {
  background-color: #fff2f3 !important;
}
</style>
<style lang="scss" scoped>
.name {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  color: #545454;
  display: block;
}
.btns {
  margin-bottom: 20px;
}
.xinxi {
  padding: 20px 20px 10px 20px;
  background: #fff;
}
.fenye {
  margin-top: 10px;
}
</style>
