<template>
  <div>
    <headertop></headertop>
    <div class="myInfo">
      <div class="left" :style="!showGzt?'height:370px':''">
        <div class="list" :class="Inv == 1 ? 'active' : ''" @click="Inv = 1">
          <span>私人助理</span>
        </div>
        <div class="list" :class="Inv == 2 ? 'active' : ''" @click="Inv = 2">
          <span>基本信息</span>
        </div>
        <div class="list" :class="Inv == 3 ? 'active' : ''" @click="Inv = 3">
          <span>地址收藏</span>
        </div>
        <!-- <div class="list" :class="Inv == 4 ? 'active' : ''" @click="Inv = 4">
          <span>商品订单</span>
        </div> -->
        <div class="list" :class="Inv == 5 ? 'active' : ''" @click="Inv = 5">
          <span>投诉管理</span>
        </div>
        <div class="list" :class="Inv == 6 ? 'active' : ''" @click="Inv = 6">
          <span>信息管理</span>
        </div>
        <div class="list" v-if="showGzt" :class="Inv == 7 ? 'active' : ''" @click="Inv = 7">
          <span>工作台</span>
        </div>
        <div class="list" @click="loginOut">
          <span>退出登录</span>
        </div>
      </div>
      <div class="right">

        <div class="userInfoMation">
          <vip v-if="Inv == 1"></vip>
          <userdata v-if="Inv == 2"></userdata>
          <addressinfo2 v-if="Inv == 3 && asia"></addressinfo2>
          <addressinfo v-if="Inv == 3 && !asia"></addressinfo>

          <!-- <order v-if="Inv == 4"></order> -->
          <tousu v-if="Inv == 5"></tousu>
          <xinxi v-if="Inv == 6"></xinxi>
          <yewu v-if="Inv == 7" @show="show"></yewu>
        </div>
      </div>
    </div>
    <bottombox></bottombox>

  </div>
</template>

<script>
import headertop from "./components/header.vue";

import vip from "./components/vip.vue";
import userdata from "./components/userdata.vue";
import addressinfo from "./components/addressinfo.vue";
import addressinfo2 from "./components/addressinfo2.vue";
// import order from "./components/order.vue";
import tousu from "./components/tousu.vue";
import xinxi from "./components/xinxi.vue";
import yewu from "./components/yewu.vue";
import bottombox from "./components/bottombox.vue";
import { businesslist } from "@/request/api.js";

export default {
  name: "home",
  components: {
    headertop,
    vip,
    userdata,
    bottombox,
    addressinfo,
    // order,
    tousu,
    xinxi,
    yewu,
    addressinfo2
  },
  data() {
    return {
      asia: true,
      Inv: 1,
      showGzt: true
    };
  },
  created() {

    if (this.$route.query.token) {
      localStorage.setItem("MemberAuthorization", this.$route.query.token);
    }

    this.$api.commonasia().then(res => {
      this.asia = res.data.asia
    })

    let query = {
      pageSize: 10,
      pageNum: 1,
    }

      businesslist(query).then((res) => {
        console.log(res);
        this.tableData = res.rows;
        if (res.rows.length > 0) {
          this.showGzt = true
        } else {
          this.showGzt = false
        }
      });



  },
  mounted() { },

  methods: {
    show(e) {
      console.log('show')
      console.log(e)
      this.showGzt = e
    },
    loginOut() {
      this.$confirm("是否确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.push({
          path: "/login",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.myInfo {
  width: 70%;
  margin: 0 auto;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  margin-top: 20px;

  .right {
    display: inline-block;
    width: calc(100% - 160px);
    background: #F5F2F2;
    margin-left: 30px;
    box-sizing: border-box;

    box-shadow: 0 0px 10px rgba(169, 168, 168, 0.28);
  }

  .left {
    width: 105px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0px 10px rgba(169, 168, 168, 0.28);
    display: inline-block;
    height: 427px;

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      font-size: 15px;
      border-bottom: 1px solid #e9e9e9;

      img {
        width: 30px;
        margin-bottom: 10px;
      }

      .img {
        display: block;
      }

      .img1 {
        display: none;
      }
    }

    .list:last-child {
      border: none;
    }

    .active {
      background: #fc7474;
      color: #fff;
    }

    .active {
      .img {
        display: none;
      }

      .img1 {
        display: block;
      }
    }
  }
}
</style>
