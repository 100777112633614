var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"xinxi"},[_vm._m(0),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"cell-style":{ textAlign: 'center' },"header-cell-style":{
        textAlign: 'center',
        backgroundColor: '#f7acb2',
        color: '#fff',
        fontSize: '15px',
        fontWeight: 400,
      }}},[_c('el-table-column',{attrs:{"prop":"name","label":"业务名称"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.goInfo(scope.row)}}},[_vm._v("进入")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.out(scope.row)}}},[_vm._v("退出")])]}}])})],1),_c('div',{staticClass:"fenye"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":_vm.query.pageSize},on:{"current-change":_vm.currentChange}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"name"},[_vm._v("工作台")])])
}]

export { render, staticRenderFns }