<template>
  <div>
    <div class="xinxi">
      <div class="title">
        <span class="name">信息发布</span>
        <span @click="$emit('back')">
          <i class="iconfont">&#xe634;</i>
          返回</span
        >
      </div>
      <div class="formBox">
        <el-form
          ref="form"
          size="small"
          :model="form"
          :rules="rules"
          label-width="80px"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="标题" prop="infoTitle">
                <el-input
                  v-model="form.infoTitle"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <!-- <el-col :span="12">
              <el-form-item label="类别" prop="fixType">
                <el-radio-group
                  v-model="form.fixType"
                  @change="getclassifyinfoList"
                >
                  <el-radio label="1">近邻</el-radio>
                  <el-radio label="2">买卖通</el-radio>
                  <el-radio label="3">分类信息</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="分类" prop="classify">
                <el-cascader
                  style="width: 100%"
                  :props="props"
                  v-model="form.classify"
                  :options="fenleiList"
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="图片">
            <el-upload
              action="https://inwaybelief.com/prod-api/common/upload"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              :limit="1"
              :on-success="fileSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <span>建议尺寸：465*240 jpg或png图片</span>

          </el-form-item>
          <el-form-item label="详情" prop="infoDetail">
            <wangEditor
              v-model="form.infoDetail"
              :isClear="isClear"
              @change="wangEditorChange"
            ></wangEditor>
          </el-form-item>

          <div class="lixifangshi">
            <el-row>
              <div
                class="titles"
                @click="addLianxi"
                style="justify-content: flex-start"
              >
                联系方式<i class="iconfont">&#xe657;</i>
              </div>
            </el-row>
            <el-row v-for="(item, index) in form.links" :key="index">
              <el-col :span="16">
                <div class="flex">
                  <el-select
                    v-model="item.contactInfoId"
                    size="small"
                    placeholder="请选择"
                    style="margin-right: 20px"
                  >
                    <el-option
                      v-for="item in linkList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <!-- <img src="@/assets/dianhua.png" class="lianxiIcon" alt="" /> -->

                  <el-input
                    v-model="item.content"
                    size="small"
                    placeholder="请输入"
                    type="text"
                  ></el-input>
                  <el-upload
                    class="upload-demo"
                    action="https://inwaybelief.com/prod-api/common/upload"
                    multiple
                    :limit="1"
                    :on-success="fileSuccess2"
                    :file-list="fileList2"
                  >
                    <el-button
                      style="margin-right: 10px"
                      size="small"
                      type="primary"
                      @click="checkImgIndex(index)"
                      >点击上传二维码</el-button
                    >
                  </el-upload>

                  <i class="iconfont delIcon" @click="delLixin(index)"
                    >&#xe718;</i
                  >
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- <div class="lixifangshi">
            <el-row>
              <div
                class="titles"
                @click="addyewu"
                style="justify-content: flex-start"
              >
                业务入口<i class="iconfont">&#xe657;</i>
              </div>
            </el-row>
            <el-row v-for="(item, index) in form.business" :key="index">
              <el-col :span="16">
                <div class="flex">
                  <el-input
                    v-model="item.name"
                    size="small"
                    placeholder="请输入名称"
                    style="width: 300px; margin-right: 20px"
                  ></el-input>

                  <el-input
                    v-model="item.link"
                    size="small"
                    placeholder="请输入链接"
                    style="width: 300px"
                  ></el-input>

                  <i class="iconfont delIcon" @click="delyewu(index)"
                    >&#xe718;</i
                  >
                </div>
              </el-col>
            </el-row>
          </div> -->
          <!-- <div class="lixifangshi">
            <el-table :data="form.links" border style="width: 100%">
              <el-table-column prop="icon" label="图标" width="100">
                <template slot-scope="scope">
                  <el-image
                    :src="scope.row.icon"
                    style="width: 50px; height: 50px"
                    fit="fill"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="名称"> </el-table-column>
              <el-table-column prop="linkPhone" label="内容">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.content"
                    placeholder="请输入"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div> -->
          <el-row>
            <!-- <el-col :span="12">
              <el-form-item label="详细地址">
                <el-input
                  v-model="form.detailAddress"
                  placeholder="请输入"
                ></el-input> </el-form-item
            ></el-col> -->
            <el-col :span="12">
              <el-form-item label="工作时间">
                <el-input
                  v-model="form.workTime"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="辖区">
                <el-cascader
                  style="width: 100%"
                  v-model="form.areas"
                  :props="{ multiple: true }"
                  :options="options"
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="">
            <el-row>
              <el-col :span="12">
                <el-form-item label="关键字">
                  <el-input
                    placeholder="请输入（按逗号分开）"
                    v-model="form.keyWord"
                    class="input-with-select"
                  >
                  </el-input> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item label="详细地址">
                  <el-input
                    placeholder="请输入"
                    v-model="form.detailAddress"
                    class="input-with-select"
                  >
                  </el-input> </el-form-item
              ></el-col>
            </el-row>
          </div>

          <div class="">
            <el-row>
              <el-col :span="12">
                <el-form-item label="经纬度">
                  <el-button @click="gooleMapDiaCheck" icon="el-icon-position"
                    >选择地址</el-button
                  >
                </el-form-item>
                <div v-if="nowPlace">
                  <div class="descsb">当前地址：{{ nowPlace }}</div>
                  <div class="descsb">经度：{{ form.latitude }}</div>
                  <div class="descsb">纬度：{{ form.longitude }}</div>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item label="距离范围">
                  <el-input-number
                    v-model="form.distance"
                    label=""
                  ></el-input-number> 千米</el-form-item
              ></el-col>
              
            </el-row>
          </div>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog title="定位"  :visible.sync="gooleMapDia" width="70%">
      <goolemap @mark="mark" ref="goolemap" ></goolemap>
      <div style="height: 20px"></div>
      <div style="margin-bottom: 20px">当前定位：{{ nowPlace }}</div>
      <div>
        <el-button type="danger" @click="checkGooleMap()">保存</el-button>
        <el-button @click="gooleMapDia = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import goolemap from "@/components/goolemap.vue";
import wangEditor from "@/components/wangEditor";

import {
  countryAreatree,
  contactInfoall,
  classifyinfoAuthList,
  neighborInfoadd,
  neighborInfoupdate,
} from "@/request/api.js";
export default {
  name: "xinxi",
  components: { goolemap, wangEditor },
  data() {
    return {
      isClear: false, //设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      wangEditorDetail: "",
      fileList2: [],
      form: {
        infoTitle: "",
        infoDetail: "",
        classify: undefined,
        infoPic: "",
        links: [],
        detailAddress: "",
        workTime: "",
        areas: [],
        latitude: "",
        distance: "",
        longitude: "",
        fixType: "3",
        keyWord: "",
        business: [],
      },
      fileList: [],
      nowPlace: "",
      gooleMapDia: false,
      xiaquList: [{}],
      lianxilist: [],
      dialogImageUrl: "",
      dialogVisible: false,
      tableData: [],
      props: {
        label: "classifyName",
        value: "id",
        children: "children",
      },
      rules: {
        infoTitle: [{ required: true, message: "请输入标题", trigger: "blur" }],
        infoDetail: [
          { required: true, message: "请输入详情", trigger: "blur" },
        ],
        classify: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      options2: [
        {
          value: "1",
          label: "电话",
        },
        {
          value: "2",
          label: "邮箱",
        },
      ],
      linkList: [],
      value: "",
      options: [],
      fenleiList: [],
      nowCheckIndex: 0,
    };
  },
  created() {
    this.form.detailAddress = this.$store.state.place;
    this.form.longitude = this.$store.state.lng;
    this.form.latitude = this.$store.state.lat;

    console.log(this.$parent);

    console.log(this.$store.state);
    this.getcountryAreatree();
    this.getcontactInfoall();

    this.getclassifyinfoList();
  },
  mounted() {
    this.wangEditorDetail = "我是默认值"; //设置富文本框默认显示内容
  },
  methods: {
    checkImgIndex(index) {
      console.log(index);
      this.nowCheckIndex = index;
    },
    fileSuccess2(res) {
      this.form.links[this.nowCheckIndex].contactPic = res.data.url;
      console.log(this.form.links);
    },
    wangEditorChange(val) {
      console.log(val);
    },
    init(e) {
      console.log("编辑返回的信息");
      console.log(e);
      setTimeout(() => {
        this.form = e;
        this.form.fixType = e.fixType + "";

        if (this.form.infoPic) {
          this.fileList.push({
            url: this.form.infoPic,
          });
        } else {
          this.fileList = [];
        }
        this.getclassifyinfoList();
      }, 500);
    },
    getclassifyinfoList() {
      classifyinfoAuthList({ fixType: this.form.fixType,nullNoChild:true }).then((res) => {
        console.log(res);
        this.fenleiList = res.data;
      });
    },
    getcountryAreatree() {
      countryAreatree({ nullNoChild: false }).then((res) => {
        console.log(res);
        this.options = res.data;
      });
    },
    getcontactInfoall() {
      contactInfoall().then((res) => {
        console.log("联系方式");
        console.log(res);
        this.linkList = res.data;
      });
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.infoId) {
            neighborInfoupdate(this.form).then((res) => {
              console.log(res);
              this.$notify({
                title: "成功",
                message: "修改成功",
                type: "success",
              });
              this.$emit("back");
            });
          } else {
            neighborInfoadd(this.form).then((res) => {
              console.log(res);
              this.$notify({
                title: "成功",
                message: "添加成功",
                type: "success",
              });
              this.$emit("back");
            });
          }
        }
      });
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;

      // this.form.infoPic = file.url
      this.dialogVisible = true;
    },
    fileSuccess(res) {
      console.log("图片上传完成");
      this.form.infoPic = res.data.url;
      console.log(res);
      console.log(this.form);
    },
    addLianxi() {
      this.form.links.push({
        contactInfoId: "",
        content: "",
        contactPic: "",
      });
    },
    addyewu() {
      this.form.business.push({
        name: "",
        link: "",
      });
    },
    delLixin(index) {
      this.form.links.splice(index, 1);
    },
    delyewu(index) {
      this.form.business.splice(index, 1);
    },
    addXiaqu() {
      this.xiaquList.unshift({});
    },
    delXiaqu(index) {
      this.xiaquList.splice(index, 1);
    },
    gooleMapDiaCheck() {
      this.gooleMapDia = true;
      this.$nextTick(() => {
        this.nowPlace = this.$store.state.place;

        this.$refs.goolemap.icon = "";
        this.$refs.goolemap.check = false;
      });
    },
    mark(e) {
      console.log(e);
      this.form.latitude = e.latitude;
      this.form.longitude = e.longitude;
      this.nowPlace = e.title;
    },
    checkGooleMap() {
      this.form.dingwei = this.nowPlace;
      this.gooleMapDia = false;
    },
  },
};
</script>

<style>
.el-dialog__wrapper{
  z-index: 200003!important;
}
</style>
<style lang="scss" scoped>
.xiaquIcon {
  position: absolute;
  font-size: 20px;
  right: -30px;
  cursor: pointer;
}

.xiaquIcon2 {
  position: absolute;
  font-size: 23px;
  right: -30px;
  cursor: pointer;
}
.lixifangshi {
  margin-left: 80px;
  margin-bottom: 10px;
  .flex {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .lianxiIcon {
      margin: 0 10px;
      width: 30px;
    }
    .delIcon {
      font-size: 25px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.titles {
  font-size: 16px;
  font-weight: bold;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .iconfont {
    margin-left: 10px;
    font-size: 18px;
  }
}
.upload-demo {
  width: 200px;
  margin-left: 10px;
  min-width: 200px;
}
.name {
  font-weight: bold;
  font-size: 20px;
  color: #545454;
  display: block;
}

.xinxi {
  padding: 20px 20px 10px 20px;
  background: #fff;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
.descsb {
  font-size: 14px;
  padding-left: 80px;
  color: #5c5959;
  margin-bottom: 4px;
}
</style>
