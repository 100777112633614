<template>
  <div class="address">
    <div class="title">
      <span
        class="name"
        style="font-size: 20px; color: #545454; font-weight: bold"
        >收藏地址</span
      >
    </div>
    <el-table
      :row-class-name="tableClass"
      :data="tableData"
      border
      style="width: 100%"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f7acb2',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 400,
      }"
    >
      <el-table-column prop="name" label="标签" width="150">
        <template slot-scope="scope">
          {{ scope.row.name ? scope.row.name : "暂无" }}
        </template>
      </el-table-column>
      <el-table-column prop="linkName" label="联系人" width="150">
        <template slot-scope="scope">
          {{ scope.row.linkName ? scope.row.linkName : "暂无" }}
        </template>
      </el-table-column>
      <el-table-column prop="linkPhone" label="电话" width="150">
        <template slot-scope="scope">
          {{ scope.row.linkPhone ? scope.row.linkPhone : "暂无" }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="linkPhone" label="是否默认" width="150">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.isDefault == 1"
            >默认地址</el-tag
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="detailAddress" label="地址"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="change(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="btn">
      <el-button type="danger" @click="addAddress">添加地址</el-button>
    </div>

    <el-dialog
      title="信息管理"
      :visible.sync="dialogVisible"
      :closeOnClickModal="false"
      width="60%"
    >
      <goolemap
        ref="goolemap"
        @mark="mark"
        @updateMaker="updateMaker"
      ></goolemap>
      <div style="height: 20px"></div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="80px"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkName">
          <el-input
            v-model="form.linkName"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="linkPhone">
          <el-input
            v-model="form.linkPhone"
            maxlength="11"
            placeholder="请输入电话"
          ></el-input>
        </el-form-item>

        <el-form-item label="详细地址" prop="detailAddress">
          <el-input
            v-model="form.detailAddress"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="danger" @click="onSubmit('form')">保存</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import goolemap from "@/components/goolemap.vue";

import {
  membermemberCollectAddressdel,
  memberCollectAddressupdate,
  memberCollectAddresslist,
  memberCollectAddressadd,
} from "@/request/api.js";
export default {
  name: "address",
  components: {
    goolemap,
  },
  data() {
    return {
      form: {
        name: "",
        linkName: "",
        linkPhone: "",
        detailAddress: "",
        isDefault: 1,
        latitude: "",
        longitude: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        linkName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        linkPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        detailAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      memberCollectAddresslist().then((res) => {
        console.log(res);
        this.tableData = res.rows;
      });
    },
    tableClass({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        //奇数行，序号不能被2整除
        return "even-row";
      } else {
        return "";
      }
    },
    change(e) {
      this.form = JSON.parse(JSON.stringify(e));
      this.dialogVisible = true;
      this.$nextTick(() => {
        console.log(this.form);
        this.$refs.goolemap.initPlace(this.form);
        this.$refs.goolemap.icon = "";
        this.$refs.goolemap.check = false;
        
      });
    },
    updateMaker(e) {
      this.form.latitude = e.latitude;
      this.form.longitude = e.longitude;
    },
    del(e) {
      console.log(e);
      this.$confirm(`是否确认删除 ?`, "提示", {
        type: "warning",
      }).then(() => {
        membermemberCollectAddressdel(e.id).then((res) => {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
        });
        this.getData();
      });
      });
      
    },
    mark(e) {
      console.log(e);
      this.form.detailAddress = e.title;
      this.form.latitude = e.latitude;
      this.form.longitude = e.longitude;
    },
    addAddress() {
      (this.form = {
        name: "",
        linkName: "",
        linkPhone: "",
        detailAddress: "",
        isDefault: 1,
        latitude: "",
        longitude: "",
      }),
        (this.dialogVisible = true);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            memberCollectAddressupdate(this.form).then((res) => {
              this.$notify({
                title: "成功",
                message: "修改成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.getData();
            });
          } else {
            memberCollectAddressadd(this.form).then((res) => {
              this.$notify({
                title: "成功",
                message: "添加成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.getData();
            });
          }
        }
      });
    },
  },
};
</script>

<style>
.even-row {
  background-color: #fff2f3 !important;
}
</style>
<style lang="scss" scoped>
.address {
  padding: 20px;
  .title {
    margin-bottom: 15px;
  }
}

.btn {
  margin-top: 20px;
}
#map2 {
  height: 600px;
  width: 100%;
}
</style>
