<template>
  <div>
    <div class="addressinfo">
      <el-radio-group
        size="small"
        v-model="query.complaintStatus"
        style="margin-bottom: 30px"
        @input="getData"
      >
        <el-radio-button label="0">待处理</el-radio-button>
        <el-radio-button label="1">处理中</el-radio-button>
        <el-radio-button label="2">完成</el-radio-button>
        <el-radio-button label="3">撤诉</el-radio-button>
        <el-radio-button label="4">上诉</el-radio-button>
        <el-radio-button label="-1">终止</el-radio-button>
      </el-radio-group>
      <el-table
        :row-class-name="tableClass"
        :data="tableData"
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f7acb2',
          color: '#fff',
          fontSize: '15px',
          fontWeight: 400,
        }"
      >
        <el-table-column prop="content" label="内容">
          <template slot-scope="scope">
            <div @click="showtextDia(scope.row.content)" class="sbtxt">
              {{ scope.row.content }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="日期"> </el-table-column>
        <el-table-column prop="handleUser" label="被投诉人">
          <template slot-scope="scope">
            {{
              scope.row.complainedUserName
                ? scope.row.complainedUserName
                : scope.row.complainedMemberUserName
                ? scope.row.complainedMemberUserName
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.complaintStatus == 0
                ? "待处理"
                : scope.row.complaintStatus == 1
                ? "处理中"
                : scope.row.complaintStatus == 2
                ? "完成"
                : scope.row.complaintStatus == 3
                ? "撤诉"
                : scope.row.complaintStatus == 4
                ? "上诉"
                : "终止"
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="140">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #00a1ff"
              size="small"
              @click="ceshu(scope.row)"
              v-if="scope.row.complaintStatus == 4"
              >撤诉</el-button
            >
            <el-button
              v-if="
                scope.row.complaintStatus == 0 ||
                scope.row.complaintStatus == 1 ||
                scope.row.complaintStatus == 2
              "
              type="text"
              size="small"
              @click="shangsu(scope.row)"
              >上诉</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="fenye">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :page-size="query.pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="投诉内容"
      :visible.sync="textDia"
      :closeOnClickModal="false"
      width="60%"
    >
      <div>投诉内容：{{ text }}</div>
      <el-button @click="textDia = false" size="small" style="margin-top:30px">关闭</el-button>

    </el-dialog>
  </div>
</template>

<script>
import {
  complaintuserList,
  complaintback,
  complaintappeal,
} from "@/request/api.js";
export default {
  name: "addressinfo",
  components: {},
  data() {
    return {
      text: "",
      textDia: false,
      tabPosition: "1",
      total: 0,
      tableData: [],
      query: {
        pageSize: 10,
        pageNum: 1,
        complaintStatus: 0,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    showtextDia(txt) {
      this.text = txt;
      this.textDia = true;
    },
    handleClick(e) {
      console.log(e);
    },
    currentChange(e) {
      this.query.pageNum = e;
      this.getData();
    },
    getData() {
      complaintuserList(this.query).then((res) => {
        console.log(res);
        this.total = res.total;
        this.tableData = res.rows;
      });
    },
    shangsu(e) {
      this.$confirm("是否确认上诉?", "提示", {
        type: "warning",
      }).then(() => {
        complaintappeal({ id: e.id }).then((res) => {
          this.$notify({
            title: "成功",
            message: "操作成功",
            type: "success",
          });
          this.getData();
        });
      });
    },
    ceshu(e) {
      this.$confirm("是否确认撤诉?", "提示", {
        type: "warning",
      }).then(() => {
        complaintback({ id: e.id }).then((res) => {
          this.$notify({
            title: "成功",
            message: "操作成功",
            type: "success",
          });
          this.getData();
        });
      });
    },
    tableClass({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        //奇数行，序号不能被2整除
        return "even-row";
      } else {
        return "";
      }
    },
    goInfo() {
      this.toInfo = true;
    },
    back() {
      this.toInfo = false;
    },
  },
};
</script>
<style>
.even-row {
  background-color: #fff2f3 !important;
}
</style>
<style lang="scss" scoped>
.sbtxt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-decoration: underline;
  color: #0391d5;
  cursor: pointer;
}
.btns {
  margin-bottom: 20px;
}
.addressinfo {
  padding: 20px 20px 10px 20px;
  background: #fff;
}
.fenye {
  margin-top: 10px;
}
</style>
