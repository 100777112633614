<template>
  <div>
    <div class="xinxi" v-if="xinxiAdd">
      <div class="title">
        <span class="name">信息管理</span>
        <el-button type="primary" size="small" @click="toAdd">发布信息</el-button>
      </div>
      <el-table :row-class-name="tableClass" :data="tableData" v-loading="loading" style="width: 100%"
        :cell-style="{ textAlign: 'center' }" :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f7acb2',
          color: '#fff',
          fontSize: '15px',
          fontWeight: 400,
        }">
        <el-table-column prop="infoCode" label="编码" width="220"> </el-table-column>
        <el-table-column prop="user" label="图片">
          <template slot-scope="scope">
            <el-image style="width: 50px; height: 50px" :src="scope.row.infoPic" fit="fit"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="infoTitle" label="标题"> </el-table-column>
        <el-table-column prop="infoMain" label="类别">
          <template slot-scope="scope">
            {{
              scope.row.fixType == 1
              ? "购物"
              : scope.row.fixType == 2
                ? "服务"
                : "信息"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="infoStatus" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.infoStatus == 0
              ? "待审核"
              : scope.row.infoStatus == 1
                ? "通过"
                : "不通过"
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="140">
          <template slot-scope="scope">
            <el-button type="text" style="color: #00a1ff" size="small" @click="toFabu(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="fenye">
        <el-pagination background layout="prev, pager, next" @current-change="currentChange" :page-size="query.pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="" v-else>
      <xinxiform ref="xinxiform" @back="back"></xinxiform>
    </div>
  </div>
</template>

<script>
import xinxiform from "./xinxiform.vue";
import {
  neighborInfouserList,
  neighborInfodelete,
  neighborInfodetail,
} from "@/request/api.js";

export default {
  name: "xinxi",
  components: {
    xinxiform,
  },
  data() {
    return {
      tabPosition: "1",
      xinxiAdd: true,
      total: 0,
      loading: true,
      tableData: [],
      query: {
        pageSize: 5,
        pageNum: 1,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    currentChange(e) {
      this.query.pageNum = e;
      this.getData();
    },
    getData() {
      neighborInfouserList(this.query).then((res) => {
        console.log(res);
        this.loading = false;
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    toFabu(e) {
      console.log(e.infoId);
      neighborInfodetail({ infoId: e.infoId }).then((res) => {
        this.xinxiAdd = false;
        this.$nextTick(() => {
          this.$refs.xinxiform.init(res.data);
        });
      });
    },
    toAdd() {
      this.xinxiAdd = false;

    },
    back() {
      this.getData();

      this.xinxiAdd = true;
    },
    tableClass({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        //奇数行，序号不能被2整除
        return "even-row";
      } else {
        return "";
      }
    },
    del(e) {
      console.log(e);
      this.$confirm(`是否删除 ${e.infoTitle} ?`, "提示", {
        type: "warning",
      }).then(() => {
        neighborInfodelete({ infoId: e.infoId }).then((res) => {
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
          });
          this.getData();
        });
      });
    },
  },
};
</script>
<style>
.even-row {
  background-color: #fff2f3 !important;
}
</style>
<style lang="scss" scoped>
.name {
  font-weight: bold;
  font-size: 20px;
  color: #545454;
  display: block;
}

.btns {
  margin-bottom: 20px;
}

.xinxi {
  padding: 20px 20px 10px 20px;
  background: #fff;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.fenye {
  margin-top: 10px;
}
</style>
